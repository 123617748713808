import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';
import {withGlobalPropsProvider, withTpaSettingsProvider} from '../providers/globalPropsProvider';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {getDefaultStyleParams} from './getDefaultStyleParams';

export const bootstrapApp = (Component: React.ComponentType) => {
  const wrapped = withStyles(withStrictMode(withTpaSettingsProvider(withGlobalPropsProvider(Component))), {
    strictMode: true,
  });
  return withDefaultStyleParams(wrapped, (props) => getDefaultStyleParams(props.host.style.styleParams));
};
