(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["wishlist"] = factory(require("react"), require("lodash"), require("react-dom"));
	else
		root["wishlist"] = factory(root["React"], root["_"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 